
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElButton, ElForm, ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-user",
  props: {
    propRoles: Array
  },
  mounted() {
    this.getOrgs();
    this.getOrgBranch();
  },
  computed: {
    computedRoleList(): any {
      return this.propRoles;
    }
  },
  data() {
    return {
      isvalid: false,
      adduserbuttonloader: false,
      modalForm: {
        username: "",
        name2: "",
        email: "",
        ipaddress: "",
        phone: "",
        pass1: "",
        pass2: "",
        selectOrgBranchList: [],
        selectorgbranchcode: "",
        roleid: "",
        selectOrgList: [],
        selectValOrg: "",
        orgtype: "03",
        ismbuser: false
      },
      useraddmodalrules: {
        selectValOrg: {
          required: true,
          message: "Заавал сонгоно уу.",
          trigger: "blur"
        },
        roleid: {
          required: true,
          message: "Заавал сонгоно уу.",
          trigger: "blur"
        },
        username: {
          required: true,
          message: "Нэвтрэх нэр хоосон байна.",
          trigger: "blur"
        },
        name2: {
          required: true,
          message: "Нэр хоосон байна.",
          trigger: "blur"
        },
        email: {
          required: true,
          email: true,
          message: "Цахим шуудан хоосон байна.",
          trigger: "blur"
        },
        ipaddress: {
          required: true,
          message: "IP хаяг хоосон байна.",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    // async getRoles() {
    //   await ApiService.get(`role`)
    //     .then(resp => {
    //       const results = resp.data;
    //       if (results.code === 0) {
    //         ElNotification({
    //           title: "Алдаа",
    //           message: results.error,
    //           type: "error",
    //           iconClass: "el-text-error"
    //         });
    //       } else if (results.code === 401) {
    //         ElNotification({
    //           title: "Анхааруулга",
    //           message: results.warning,
    //           type: "warning",
    //           iconClass: "el-text-warning"
    //         });
    //       } else {
    //         this.modalForm.selectRoles = results.response.map(org => ({
    //           roleid: org.roleid,
    //           rolename: org.rolename
    //         }));
    //       }
    //     })
    //     .catch(() => {
    //       ElNotification({
    //         title: "Алдаа",
    //         message: "Өгөгдөл уншихад алдаа гарлаа.",
    //         type: "error",
    //         iconClass: "el-text-error"
    //       });
    //     });
    // },
    async getOrgs() {
      await ApiService.get(`org`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.modalForm.selectOrgList = results.response.map(org => ({
              selectValOrg: org.orgcode,
              selectOrgTypeCode: org.orgtypecode,
              selectLabelOrg: org.orgname
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        });
    },
    async getOrgBranch() {
      await ApiService.get(`orgbranch`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.modalForm.selectOrgBranchList = results.response.map(it => ({
              selectorgcode: it.orgcode,
              selectorgbranchcode: it.orgbranchcode,
              selectbranchname: it.branchname
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        });
    },
    async saveUser() {
      if (this.modalForm.username.length < 2) return;
      if (this.modalForm.name2.length < 2) return;
      if (this.modalForm.email.length < 2) return;
      if (this.modalForm.ipaddress.length < 2) return;
      if (!this.modalForm.roleid) return;
      if (!this.modalForm.selectValOrg) return;
      this.adduserbuttonloader = true;
      await ApiService.post(`users`, {
        params: {
          username: this.modalForm.username,
          name2: this.modalForm.name2,
          email: this.modalForm.email,
          ipaddress: this.modalForm.ipaddress,
          phone: this.modalForm.phone,
          pass1: this.modalForm.pass1,
          pass2: this.modalForm.pass2,
          roleid: this.modalForm.roleid,
          orgcode: this.modalForm.selectValOrg,
          orgbranchcode: this.modalForm.selectorgbranchcode,
          ismbuser: this.modalForm.ismbuser ? 0 : 1
        }
      })
        .then(resp => {
          this.adduserbuttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.$emit("refreshevent");
              this.hidemodalbutton?.$el.click();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.adduserbuttonloader = false;
          Swal.fire({
            text: "Хэрэглэгч нэмэхэд алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    }
  },
  setup() {
    const addorgmodalref = ref<InstanceType<typeof ElForm>>();
    const hidemodalbutton = ref<InstanceType<typeof ElButton>>();
    const formSubmit = () => {
      addorgmodalref.value?.validate(async valid => {
        return !!valid;
      });
    };

    const clearField = () => {
      addorgmodalref.value?.resetFields();
    };

    return {
      addorgmodalref,
      hidemodalbutton,
      clearField,
      formSubmit
    };
  }
});
