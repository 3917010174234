
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import AddUser from "@/components/modals/userAddModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";
import { ElButton, ElForm, ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Users",
  components: { AddUser },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Хэрэглэгчид");
    });
    const editUserForm = ref<InstanceType<typeof ElForm>>();
    const changeXmlPassForm = ref<InstanceType<typeof ElForm>>();
    const changeUserPassForm = ref<InstanceType<typeof ElForm>>();
    const submitValidateEditButton = ref<InstanceType<typeof ElButton>>();

    const formSubmit = () => {
      editUserForm.value?.validate(async valid => {
        if (valid) submitValidateEditButton.value?.$el.click();
        else return valid;
      });
    };

    const formSubmitXmlPass = () => {
      changeXmlPassForm.value?.validate(async valid => {
        return !!valid;
      });
    };

    const formSubmitUserPass = () => {
      changeUserPassForm.value?.validate(async valid => {
        return !!valid;
      });
    };

    const clearField = () => {
      editUserForm.value?.resetFields();
    };

    const clearFieldXmlPass = () => {
      changeXmlPassForm.value?.resetFields();
    };

    const clearFieldUserPass = () => {
      changeUserPassForm.value?.resetFields();
    };

    return {
      editUserForm,
      changeXmlPassForm,
      changeUserPassForm,
      submitValidateEditButton,
      formSubmitXmlPass,
      formSubmitUserPass,
      clearFieldXmlPass,
      clearFieldUserPass,
      clearField,
      formSubmit
    };
  },
  mounted() {
    this.refresh();
    this.deletemodal.userdeletemodal = new Modal(
      this.$refs.deleteUserModal as HTMLDivElement
    );
    this.editmodal.usereditmodal = new Modal(
      this.$refs.editUserModal as HTMLDivElement
    );
    this.editmodal.usereditresponsemodal = new Modal(
      this.$refs.editUserResponseModal as HTMLDivElement
    );
    this.changepassmodal.changeuserpassmodal = new Modal(
      this.$refs.changeUserPassModal as HTMLDivElement
    );
    this.changexmlpassmodal.changexmlpassmodal = new Modal(
      this.$refs.changeXmlPassModal as HTMLDivElement
    );
  },
  data() {
    return {
      changeLockLoader: false,
      changepassmodal: {
        changeuserpassmodal: {} as Modal,
        changepassform: {
          username: "",
          newpassword: ""
        },
        changepassformrules: {
          newpassword: [
            {
              required: true,
              message: "Нууц үг хоосон байна.",
              trigger: "blur"
            },
            {
              min: 8,
              message: "Урт хамгийн багадаа 8-аас их байна.",
              trigger: "blur"
            }
          ]
        }
      },
      changexmlpassmodal: {
        changexmlpassmodal: {} as Modal,
        changepassform: {
          username: "",
          newpassword: ""
        },
        changepassformrules: {
          newpassword: [
            {
              required: true,
              message: "Нууц үг хоосон байна.",
              trigger: "blur"
            },
            {
              min: 8,
              message: "Урт хамгийн багадаа 8-аас их байна.",
              trigger: "blur"
            }
          ]
        }
      },
      deletemodal: {
        userdeletemodal: {} as Modal,
        username: ""
      },
      editmodal: {
        usereditresponsemodal: {} as Modal,
        usereditmodal: {} as Modal,
        username: "",
        name2: "",
        email: "",
        ipaddress: "",
        roleid: "",
        phone: ""
      },
      editmodalform: {
        username: "",
        name2: "",
        email: "",
        ipaddress: "",
        roleid: "",
        selectRoles: [],
        phone: ""
      },
      editmodalformrules: {
        name2: {
          required: true,
          message: "Нэр хоосон байна.",
          trigger: "blur"
        },
        email: {
          required: true,
          message: "Цахим шуудан хоосон байна.",
          trigger: "blur"
        },
        ipaddress: {
          required: true,
          message: "IP хаяг хоосон байна.",
          trigger: "blur"
        },
        roleid: {
          required: true,
          message: "Заавал сонгоно уу.",
          trigger: "blur"
        }
      },
      edituserbuttonloader: false,
      deleteuserbuttonloader: false,
      edituserchangepassxmlloader: false,
      edituserchangepassuserloader: false,
      page: 1,
      pageSize: 10,
      customerSearch: "",
      delusername: "",
      loadingTable: true,
      tableData: [],
      selectData: [
        {
          selectVal: "07",
          selectLabel: "Бүгд"
        },
        {
          selectVal: "03",
          selectLabel: "Банк"
        },
        {
          selectVal: "04",
          selectLabel: "ББСБ"
        },
        {
          selectVal: "05",
          selectLabel: "ХЗХ"
        },
        {
          selectVal: "06",
          selectLabel: "Бусад"
        }
      ],
      selectVal: "07"
    };
  },
  methods: {
    refresh() {
      this.getUsers();
      this.getRoles();
    },
    async unlockUser(username) {
      this.changeLockLoader = true;
      await ApiService.put(`changepassword`, {
        params: {
          username: username
        }
      })
        .then(resp => {
          if (resp.data.code === 1) {
            ElNotification({
              title: "Амжилттай",
              message: resp.data.response,
              type: "success"
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            ElNotification({
              title: "Алдаа",
              message: resp.data.error,
              type: "error"
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Lock гаргахад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.changeLockLoader = false;
          const da: any = this.tableData.find(e => e["username"] === username);
          if (da) {
            da["accountLocked"] = !da["accountLocked"];
          }
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** xml change pass modal functions
     ***************************************
     ***************************************
     ***************************************/
    changexmlpasshidebutton() {
      this.changexmlpassmodal.changexmlpassmodal.hide();
    },
    changexmlpassshowbutton(event) {
      this.changexmlpassmodal.changepassform.username = event.currentTarget.getAttribute(
        "data-id"
      );
      this.changexmlpassmodal.changexmlpassmodal.show();
    },
    async changeXmlPass() {
      if (this.changexmlpassmodal.changepassform.newpassword.length < 8) return;
      this.edituserchangepassxmlloader = true;
      await ApiService.post(`changepassword`, {
        params: {
          type: "xml",
          username: this.changexmlpassmodal.changepassform.username,
          newpassword: this.changexmlpassmodal.changepassform.newpassword
        }
      })
        .then(resp => {
          this.edituserchangepassxmlloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.changexmlpasshidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.edituserchangepassxmlloader = false;
          Swal.fire({
            text: "Хэрэглэгч устгахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** user change pass modal functions
     ***************************************
     ***************************************
     ***************************************/
    changeuserpasshidebutton() {
      this.changepassmodal.changeuserpassmodal.hide();
    },
    changeuserpassshowbutton(event) {
      this.changepassmodal.changepassform.username = event.currentTarget.getAttribute(
        "data-id"
      );
      this.changepassmodal.changeuserpassmodal.show();
    },
    async changeUserPass() {
      if (this.changepassmodal.changepassform.newpassword.length < 8) return;
      this.edituserchangepassuserloader = true;
      await ApiService.post(`changepassword`, {
        params: {
          type: "user",
          username: this.changepassmodal.changepassform.username,
          newpassword: this.changepassmodal.changepassform.newpassword
        }
      })
        .then(resp => {
          this.edituserchangepassuserloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.changeuserpasshidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.edituserchangepassuserloader = false;
          Swal.fire({
            text: "Хэрэглэгч устгахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** user delete modal functions
     ***************************************
     ***************************************
     ***************************************/
    deleteuserhidebutton() {
      this.deletemodal.userdeletemodal.hide();
    },
    deleteusershowbutton(event) {
      this.deletemodal.username = event.currentTarget.getAttribute("data-id");
      this.deletemodal.userdeletemodal.show();
    },
    async deleteUser() {
      this.deleteuserbuttonloader = true;
      await ApiService.delete(`users`, {
        params: {
          username: this.deletemodal.username
        }
      })
        .then(resp => {
          this.deleteuserbuttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.refresh();
              this.deleteuserhidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.deleteuserbuttonloader = false;
          Swal.fire({
            text: "Хэрэглэгч устгахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** user edit modal functions
     ***************************************
     ***************************************
     ***************************************/
    edituserrespmodalhidebutton() {
      this.editmodal.usereditresponsemodal.hide();
    },
    edituserrespmodalshowbutton() {
      this.editmodal.usereditresponsemodal.show();
    },
    edituserhidebutton() {
      this.editmodal.usereditmodal.hide();
    },
    editusershowbutton(row) {
      this.editmodalform.username = row.username;
      this.editmodalform.name2 = row.name2;
      this.editmodalform.email = row.email;
      this.editmodalform.ipaddress = row.ipaddress;
      this.editmodalform.roleid = row.roleid;
      this.editmodalform.phone = row.phone;
      this.editmodal.usereditmodal.show();
    },
    async editUser() {
      this.edituserbuttonloader = true;
      await ApiService.put(`users`, {
        params: {
          username: this.editmodalform.username,
          name2: this.editmodalform.name2,
          email: this.editmodalform.email,
          ipaddress: this.editmodalform.ipaddress,
          roleid: this.editmodalform.roleid,
          phone: this.editmodalform.phone
        }
      })
        .then(resp => {
          this.edituserbuttonloader = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.refresh();
              this.edituserrespmodalhidebutton();
              this.edituserhidebutton();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.edituserbuttonloader = false;
          Swal.fire({
            text: "Хэрэглэгч засхад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    /***************************************
     ***************************************
     ***************************************
     *************************************** users component functions
     ***************************************
     ***************************************
     ***************************************/
    handleCurrentChange(val) {
      this.page = val;
    },
    handleChange(value) {
      this.pageSize = value;
    },
    async getRoles() {
      await ApiService.get(`role`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.editmodalform.selectRoles = results.response.map(org => ({
              roleid: org.roleid,
              rolename: org.rolename
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        });
    },
    async getUsers() {
      await ApiService.get(`users`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.tableData = results.response.map(org => ({
              username: org.username,
              name2: org.name2,
              orgcode: org.orgcode,
              orgname: org.org,
              email: org.email,
              ipaddress: org.ipaddress,
              ismbuser: org.ismbuser,
              orgbranchcode: org.orgbranchcode,
              roleid: org.roleid,
              accountLocked: org.accountLocked as boolean,
              rolename: org.rolename,
              orgtype: org.orgtype,
              password: org.password,
              phone: org.phone,
              searchfield: org.searchfield
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    }
  }
});
